window.addEventListener('load', function () {
  // element that will be wrapped
  var el = document.querySelectorAll('.wp-block-media-text');

  el.forEach(function (item) {
    // create wrapper container
    var wrapper = document.createElement('div');
    // insert wrapper before el
    item.parentNode.insertBefore(wrapper, item);

    wrapper.classList.add('alignfull', 'wrapper__wp-block-media-text');

    if (item.classList.contains('has-media-on-the-right')) {
      wrapper.classList.add('wrapper__wp-block-media-text__media-right');
    } else {
      wrapper.classList.add('wrapper__wp-block-media-text__media-left');
    }
    var imgBg = document.createElement('img');
    imgBg.setAttribute(
      'src',
      '/wp-content/themes/signatureboisbriand.com/assets/images/shape-1.png'
    );
    imgBg.classList.add('wp-block-media-text__bg');
    wrapper.appendChild(imgBg);

    item.classList.add('container');

    // move el into wrapper
    wrapper.appendChild(item);
  });
});
